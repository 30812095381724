import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { Dialog, IconButton, styled } from '@mui/material';
import { RiCloseLine } from 'react-icons/ri';

import { investorApiClient } from 'api';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { useUserDetails } from 'providers/user-details';
import { DefaultLanguage } from 'types';

import { KycContext } from './context';
import { IUncompletedTaskGroup } from './types';
import { Language } from 'types';
import { KycRecord } from 'api/investor-client';

const StyledPageIFrame = styled('iframe')({
  position: 'fixed',
  inset: 0,
  height: '100%',
  width: '100%',
  border: 'none',
});

const StyledDialogIFrame = styled('iframe')({
  position: 'relative',
  height: '100%',
  width: '100%',
  border: 'none',
});

interface Props {}

export const KycProvider = ({ children }: PropsWithChildren<Props>) => {
  const isKycEnabled = process.env.REACT_APP_KYC_ENABLED === 'true';

  const [link, setLink] = useState<string | null>(null);
  const [kycRecord, setKycRecord] = useState<KycRecord | null>(null);
  const [taskFlowFinished, setTaskFlowFinished] = useState(false);

  const { selectedAccount } = useInvestorAccounts();
  const { initiallyLoaded, currentLanguage, setLanguage } = useUserDetails();

  const { execute: refetchKycRecord } = useAsync(async () => {
    if (!isKycEnabled || !selectedAccount || !initiallyLoaded) return;

    // KYC Record
    const { data: kycRecord } = await investorApiClient.kyc.kycApiControllerFindKycRecordById();
    setKycRecord(kycRecord ?? null);
    const finished = kycRecord?.continued ?? false;

    if (!finished) {
      // Current unfinished task
      const { data: task } = await investorApiClient.kyc.kycApiControllerGenerateTaskLink();
      const link = (task as any).link;
      setLink(link ?? null);
      if (!link) {
        setTaskFlowFinished(true);
      } else {
        setTaskFlowFinished(false);
      }
    } else {
      setTaskFlowFinished(true);
    }
  }, [selectedAccount, initiallyLoaded]);

  // --- uncompletedTaskGroups ---

  const [rawTaskGroups, setRawTaskGroups] = useState<IUncompletedTaskGroup[]>([]);

  const { execute: refetchUncompletedTaskGroups } = useAsync(async () => {
    if (!isKycEnabled || !selectedAccount || !initiallyLoaded) return;
    // prettier-ignore
    const { data } = await investorApiClient.kyc.kycApiControllerFindUncompletedTaskGroupsByKycRecord({lang: currentLanguage ?? DefaultLanguage});
    setRawTaskGroups((data as IUncompletedTaskGroup[]) ?? []);
  }, [selectedAccount, initiallyLoaded, currentLanguage]);

  const uncompletedTaskGroups = useMemo(() => {
    if (!rawTaskGroups) return [];

    return rawTaskGroups.map((tg) => {
      try {
        let link = new URL(tg.link);
        const urlParams = new URLSearchParams(link.search);
        urlParams.set('lang', currentLanguage ?? DefaultLanguage);
        link.search = `?${urlParams.toString()}`;

        return { ...tg, link: link.toString() };
      } catch {
        return tg;
      }
    });
  }, [currentLanguage, rawTaskGroups]);

  // --- end of uncompletedTaskGroups ---

  useEffect(() => {
    const handleMessage = (event: Event) => {
      const eventData = (event as any).data as Record<string, any>;
      if (typeof eventData !== 'object') return;

      switch (eventData['type']) {
        case 'finish-task-flow': {
          setTaskFlowFinished(true);
          setLink(null);
          refetchKycRecord();
          refetchUncompletedTaskGroups();
          break;
        }

        case 'finish-task-subflow': {
          setLink(null);
          refetchKycRecord();
          refetchUncompletedTaskGroups();
          break;
        }

        case 'change-language': {
          setLanguage(eventData.language as Language);
          refetchKycRecord();
          break;
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [refetchKycRecord, refetchUncompletedTaskGroups, setLanguage]);

  const setUnfinishedTaskGroupLink = useCallback(
    (newLink: string) => {
      if (link) return;
      setLink(newLink);
    },
    [link],
  );

  const handleDialogClose = () => {
    setLink(null);
    refetchKycRecord();
    refetchUncompletedTaskGroups();
  };

  const values = useMemo(
    () => ({
      kycRecord,
      uncompletedTaskGroups,
      taskFlowFinished,
      setUnfinishedTaskGroupLink,
    }),
    [kycRecord, setUnfinishedTaskGroupLink, taskFlowFinished, uncompletedTaskGroups],
  );

  if (isKycEnabled && !taskFlowFinished) {
    return (
      <StyledPageIFrame title="KYC" src={link ?? undefined}>
        Loading
      </StyledPageIFrame>
    );
  }

  return (
    <KycContext.Provider value={values}>
      {children}

      <Dialog
        open={!!link}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            height: '100vh',
            width: '100%',
            maxWidth: '760px',
            padding: 0,
            overflow: 'auto',
          },
        }}
      >
        <StyledDialogIFrame title="KYC" src={link ?? undefined}>
          Loading
        </StyledDialogIFrame>

        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={handleDialogClose}
        >
          <RiCloseLine />
        </IconButton>
      </Dialog>
    </KycContext.Provider>
  );
};
